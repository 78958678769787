import React from 'react';
import styled from 'styled-components';
import TermsContent from '../../Components/TermsContent';

function Terms(props) {
  return (
    <Wrapper>
      <div className="content">
        <TermsContent />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  background-color: #f0f0f0;

  & > .content {
    max-width: var(--contentMaxWidth);
    padding: 20px;
    margin: 0 auto;
    background-color: #fff;
  }
`;
export default Terms;
